.success-banner {
  margin-bottom: 15px;
}
.success-banner .Polaris-Banner--withinPage.Polaris-Banner--statusSuccess {
  border-top: 0.5rem solid rgb(0, 164, 124);
}
.critical .Polaris-Banner--withinPage.Polaris-Banner--statusCritical {
  border-top: 0.5rem solid rgb(255, 163, 163);
}
.info .Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
  border-top: 0.5rem solid rgb(78, 166, 170);
}

.requirement-card {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
}

.about-channel {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.company-name {
  font-weight: bold;
  font-size: 13px;
}

.company-terms {
  font-size: 13px;
  color: rgb(44, 110, 203);
  font-weight: 500;
}

.company-terms:hover {
  text-decoration: underline;
  cursor: pointer;
}

.right {
  display: flex;
  column-gap: 5px;
}

.account-section {
  display: flex;
  justify-content: space-between;
}

.account-section-left {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.Polaris-ButtonGroup__Item .Polaris-Button--primary {
  background-color: rgb(216, 44, 13);
  color: #fff;
}

.Polaris-ButtonGroup__Item:hover .Polaris-Button--primary {
  background-color: rgb(216, 44, 13);
  color: #fff;
}

.Polaris-Button--primary.Polaris-Button--disabled:hover {
  background-color: rgb(241, 241, 241);
  color: rgba(0, 0, 0, 0);
}

.linkable {
  color: rgb(44, 110, 203);
  cursor: pointer;
}

.product-loading {
  display: flex;
  justify-content: center;
  height: 126px;
  align-items: center;
}

.commission-flex {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
}
