.account-page {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.left-side {
  position: absolute;
  width: 20%;
}

.image {
  flex-grow: 1;
  margin-left: 34%;
}

.sign-up-banner-container {
  display: flex;
  width: 90%;
  margin-top: 18px;
  margin-bottom: -40px;
  margin-left: 6%;
}

.sign-up-banner {
  flex-grow: 1;
}

.account-information {
  position: absolute;
  top: 20vh;
  left: 6vw;
  display: grid;
  row-gap: 16px;
}

.title {
  max-width: 390px;
  margin-bottom: 8px;
  font-weight: 500;
}

.connect-account .Polaris-Button {
  background: rgb(112, 132, 203) !important;
  padding: 0 35px !important;
  color: #fff !important;
}

.company-logo-wrapper {
  display: flex;
  align-items: center;
  margin-left: 46px;
  margin-top: 30px;
}

.account-logo {
  width: 50px;
  height: 48px;
}

.sign-up {
  font-weight: bold;
  cursor: pointer;
  color: #303c4f;
}

.sign-up:hover {
  text-decoration: underline;
}

.sign-in-logo {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: #303c4f;
}

.Polaris-FormLayout__Item .Polaris-Button--fullWidth {
  border-radius: 33px;
  background-color: #6973f3 !important;
  color: #fff;
}
.Polaris-FormLayout__Item .Polaris-Button--fullWidth:hover {
  background-color: #5b63c1 !important;
}

.error-helper {
  font-size: 12px;
}

.Polaris-Modal-CloseButton {
  margin-top: 8px !important;
  margin-right: 5px !important;
}

.Polaris-Spinner--sizeSmall svg {
  fill: #fff !important;
}

.Polaris-Button--plain.Polaris-Button--iconOnly {
  z-index: 1;
}

.term_and_conditions {
  color: #6973f3;
  cursor: pointer;
  text-decoration: none;
}

.required{
  color: red;
}

.Polaris-Frame{
  min-height: unset !important;
}