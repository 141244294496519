.processing {
    position: absolute;
    width: 100%;
    top: 25%;
    display: grid;
    place-content: center;
}

.card {
    width: 520px;
    padding: 10px 20px 37px 20px;
    background-color: #FFFFFF;
    border-radius: 13px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
    border: 2px solid #E9F3FE;
}

.header {
    /* border-bottom: 1.5px solid #E5EAF4; */
}

.title {
    color: #303C4F;
    font-family: "Brandon Text Medium";
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
}

.sub-title {
    color: #5B7282;
    font-family: "Brandon Text";
    font-size: 14px;
    line-height: 20px;
}

.card-body{
    height: 200px;
}

.body-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.logo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 100px;
    width: 100px;
    margin: 40px;
    display: inline-block;
}

.animate {
    animation: bounceIn 3s infinite;
    transform: rotate(0deg) scale(1) translateZ(0);
    transition: all 0.4s cubic-bezier(.8, 1.8, .75, .75);
}

@keyframes bounceIn {
    0% {
        opacity: 1;
        transform: scale(.3);
    }

    20% {
        opacity: 1;
        transform: scale(1.05);
    }

    25% {
        opacity: 1;
        transform: scale(.9);
    }

    28% {
        opacity: 1;
        transform: scale(1);
    }
}

.transfer {
    height: 30px;
    width: 30px;
    margin: 20px 40px 70px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-size: contain;
    /* background-image: url('../assets//images//wishlist_logo_dark.svg'); */
}

.transfer-icon {
    font-size: 30px;
    padding-left: 35px;
    padding-right: 35px;
    height: 100%;
    align-content: center;
    display: grid;
}

.wishlist{
    background-image: url('../assets//images//wishlist_logo_dark.svg');
}

.shopify {
    background-image: url('../assets//images/shopify-logo.png');
    margin-bottom: 18px;
}


.logo-loader {
    width: 100%;
    height: 250px;
    position: relative;

}

.logo-loader-alignment {
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 40px);
    transform: translate(-50%, -50%);
}

.line{
    border-bottom: 1.5px solid #E5EAF4;
}

.status{
    
}